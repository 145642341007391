import {
  Button,
  Table,
  Typography,
  DatePicker,
  Input,
  Space,
  Select,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;

import { ModalInfoType } from "type";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import _ from "lodash";
import { memo, useState } from "react";
const { Title } = Typography;
import CUContract from "./CUContract";
import { setQuery } from "features/contractSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import RContract from "./RContract";
function tabContent(data: any) {
  const { query, contracts } = useSelector(
    (state: any) => state.contractReducer
  );

  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useDispatch();
  const listMonths = [
    {
      value: 0,
      label: "Tất cả",
    },
    {
      value: 1,
      label: "Tháng 1",
    },
    {
      value: 2,
      label: "Tháng 2",
    },
    {
      value: 3,
      label: "Tháng 3",
    },
    {
      value: 4,
      label: "Tháng 4",
    },
    {
      value: 5,
      label: "Tháng 5",
    },
    {
      value: 6,
      label: "Tháng 6",
    },
    {
      value: 7,
      label: "Tháng 7",
    },
    {
      value: 8,
      label: "Tháng 8",
    },
    {
      value: 9,
      label: "Tháng 9",
    },
    {
      value: 10,
      label: "Tháng 10",
    },
    {
      value: 11,
      label: "Tháng 11",
    },
    {
      value: 12,
      label: "Tháng 12",
    },
  ];
  const filterByYear = (e: any) => {
    const year =
      _.isNull(e) || _.isNaN(e) || _.isUndefined(e) ? "" : moment(e).year();
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        year: year, //Trường lọc data là name và
      })
    );
  };

  const handleSearch = () => {
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        searchValue: searchText,
        page: 1, //Trường lọc data là name và
      })
    );
  };

  const handleChangeFilterType = (value: string) => {
    dispatch(
      setQuery({
        ...query,
        populate:
          "contractDetails.servicePackageId.farmScaleTTNId farmScaleTTTId,userId,techSupporter,dataEntryClerk", // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        page: 1, //Trường lọc data là name và
      })
    );
  };

  const filterByMonthAndYear = (e: any) => {
    if (e == 0) {
      dispatch(
        setQuery({
          ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
          month: undefined,
        })
      );
    } else {
      dispatch(
        setQuery({
          ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
          month: e, //Trường lọc data là name và
        })
      );
    }
  };

  function servicePackActive(record: any) {
    const now = moment();
    return (
      record.contractDetails.find(
        (contractDetail: any) =>
          contractDetail.status === "doing" &&
          now.isBefore(moment(contractDetail.endDateContract))
      ) || _.last(record.contractDetails)
    );
  }

  const columns: any = [
    ...(data.activeKey !== "3"
      ? [
          {
            title: "Ngày đăng ký",
            dataIndex: "contractDetails",
            align: "center",
            key: "contractDetails",
            width: 150,
            render: (val: any) => {
              const service = servicePackActive({
                contractDetails: val,
              });
              if ((val ?? []).length === 0 || !service) return "";
              return (
                <div style={{ textAlign: "center" }}>
                  {moment(service.startDateContract).format("DD/MM/YYYY")}
                </div>
              );
            },
          },
        ]
      : []),
    {
      title: "Mã HĐ/Chủ trại",
      dataIndex: "code",
      key: "code",
      width: 150,
      render: (val: any, record: any) => {
        return (
          <div>
            <p>{val}</p>
            <b>{record?.userId.name}</b>
          </div>
        );
      },
    },
    {
      title: "Quy mô trại",
      dataIndex: "contractDetails",
      key: "contractDetails",
      render: (val: any) => {
        const service = servicePackActive({
          contractDetails: val,
        });
        return (
          <div>
            <p>
              <b>Nái: </b>
              {service?.servicePackageId?.farmScaleTTNId?.name ?? 0}
            </p>
            <p>
              <b>Thịt: </b>
              {service?.servicePackageId?.farmScaleTTTId?.name ?? 0}
            </p>
          </div>
        );
      },
    },

    {
      title: "Triển khai",
      dataIndex: "userId",
      key: "userId",
      width: 200,
      render: (val: any) => {
        return (
          <div>
            <p>
              {val?.regUserHerdBody?.typeStart === "working"
                ? "Trại đang hoạt động"
                : "Trại mới"}
            </p>
            <p>
              {val?.regUserHerdBody?.monitoring
                .map((item: any) => {
                  if (item === "excel") {
                    return "File excel";
                  } else if (item === "note") {
                    return "Sổ ghi chép";
                  } else {
                    return "Hình thức khác";
                  }
                })
                .join(", ")}
            </p>
          </div>
        );
      },
    },
    {
      title: "Gói thuê bao",
      dataIndex: "contractDetails",
      key: "contractDetails",
      width: 200,
      render: (val: any) => {
        return (
          <>{val.map((item: any) => item.servicePackageId.name).join(", ")}</>
        );
      },
    },
    {
      title:
        data.activeKey === "3"
          ? "Các gói thuê bao"
          : data.activeKey === "2"
          ? "Ngày hết hạn"
          : "Thời hạn",
      dataIndex: "contractDetails",
      key: "contractDetails",
      align: data.activeKey !== "3" ? "center" : "left",
      width: 200,
      render: (val: any) => {
        if (data.activeKey === "3") {
          return val.map((v: any, idx: number) => (
            <p key={idx} style={{ margin: 0 }}>
              {v?.servicePackageId?.name ?? ""}:{" "}
              <span style={{ fontWeight: 500 }}>
                {moment(v?.startDateContract).format("DD/MM/YYYY")}{" "}
              </span>
              -{" "}
              <span style={{ fontWeight: 500 }}>
                {moment(v?.endDateContract).format("DD/MM/YYYY")}
              </span>
            </p>
          ));
        }
        const service = servicePackActive({ contractDetails: val });
        if (!service) return "";
        return (
          <div style={{ textAlign: "center" }}>
            {moment(service?.endDateContract).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "",
      key: "",
      width: 200,
      render: (__: any, record: any) => {
        if (!!record?.techSupporter || !!record?.dataEntryClerk) {
          return (
            <div style={{}}>
              <p style={{ margin: 0, color: "#1890FF", fontWeight: 500 }}>
                Đã hỗ trợ
              </p>
              {record?.techSupporter ? (
                <p style={{ margin: 0 }}>
                  Kĩ thuật viên:{" "}
                  <span style={{ fontWeight: 500 }}>
                    {record?.techSupporter?.name ?? ""}
                  </span>
                </p>
              ) : (
                <></>
              )}
              {record?.dataEntryClerk ? (
                <p style={{ margin: 0 }}>
                  Nhân viên nhập liệu:{" "}
                  <span style={{ fontWeight: 500 }}>
                    {record?.dataEntryClerk?.name ?? ""}
                  </span>
                </p>
              ) : (
                <></>
              )}
            </div>
          );
        }
        return (
          <div style={{ color: "#00B578", fontWeight: 500 }}>Đăng kí mới</div>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Select
            showSearch
            style={{ width: "200px" }}
            placeholder="Trạng thái"
            options={[
              {
                label: "Đăng kí mới",
                value: "trial",
              },
              {
                label: "Đã hỗ trợ",
                value: "doing",
              },
            ]}
            allowClear
            onChange={handleChangeFilterType}
            // onSearch={handleSearchFilterType}
            optionFilterProp="children"
            // filterOption={(input, option) =>
            //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            // }
          />
          <DatePicker
            picker="year"
            placeholder="Chọn năm HĐ"
            onChange={(e) => filterByYear(e)}
          />
          {/* <DatePicker
            picker="month"
            placeholder="Chọn tháng HĐ"
            onChange={(e) => filterByMonthAndYear(e)}
          /> */}
          <Select
            placeholder="Chọn tháng"
            onChange={(e) => filterByMonthAndYear(e)}
            style={{ width: "100%" }}
            options={listMonths}
            clearIcon
          />
          <Search
            placeholder="Tìm theo mã hợp đồng, tên đăng nhập"
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 320 }}
          />
          {data.activeKey === "2" ? (
            <Button
              type="primary"
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Thêm mới hợp đồng khách hàng",
                  content: (
                    <CUContract
                      idUpdate={undefined}
                      getContractByStatuss={data.getContractByStatuss}
                    />
                  ),
                  size: 550,
                };
                dispatch(setModal(payload));
              }}
            >
              <PlusOutlined />
              Thêm mới
            </Button>
          ) : (
            ""
          )}
        </Space>
      </div>{" "}
      <Table
        columns={columns}
        rowKey={"id"}
        onRow={(record: any) => ({
          onClick: () => {
            const payload: ModalInfoType = {
              open: true,
              title: "Chi tiết hợp đồng khách hàng",
              content: (
                <RContract
                  record={record}
                  activeKey={data?.activeKey}
                  getContractByStatuss={data.getContractByStatuss}
                />
              ),
              size: 1400,
            };
            dispatch(setModal(payload));
          },
        })}
        dataSource={_.get(contracts, "results", [])}
        pagination={{
          total: _.get(contracts, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default memo(tabContent);
