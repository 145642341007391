import { useEffect, useState } from "react";
import { Button, Table, Typography, Space, notification, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { PayloadType, AdminDataType } from "type";
import { useDispatch } from "react-redux";
import _ from "lodash";
import queryString from "query-string";
import moment from "moment";
import CUContract from "../CUContract";
import { closePackService } from "features/contractSlice";
const { Title } = Typography;

function Service(dataView: any) {
  const dispatch = useDispatch();
  const [dataRecord, setDataRecord] = useState<any>({});
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const [isChildModalVisible, setIsChildModalVisible] = useState(false);
  const handleUpdateDataRecord = (updatedData: any) => {
    setDataRecord(updatedData);
  };
  useEffect(() => {
    setDataRecord(dataView?.data);
  }, [dataView]);

  function moneyFormat(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const columns: ColumnsType<AdminDataType> = [
    {
      title: "Tên gói",
      dataIndex: ["servicePackageId", "name"],
      key: "servicePackageId.name",
    },
    {
      title: "Ngày đăng ký",
      dataIndex: "startDateContract",
      key: "startDateContract",
      align: "center",
      render: (val) => {
        return moment(val).format("DD/MM/YYYY");
      },
    },
    {
      title: "Thời hạn (tháng)",
      dataIndex: "time",
      key: "time",
      align: "center",
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "endDateContract",
      key: "endDateContract",
      align: "center",
      render: (val) => {
        return moment(val).format("DD/MM/YYYY");
      },
    },
    {
      title: "Tổng tiền (VNĐ)",
      dataIndex: "remainMoney",
      key: "remainMoney",
      align: "center",
      render: (val) => {
        return moneyFormat(val);
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (val, record) => {
        if (moment().isAfter(moment(_.get(record, "endDateContract")))) {
          return (
            <span
              style={{
                color: "#FAAD14",
              }}
            >
              Đã hết hạn
            </span>
          );
        }
        return val === "doing" ? (
          <span
            style={{
              color: "#389E0D",
            }}
          >
            Đang sử dụng
          </span>
        ) : (
          <span
            style={{
              color: "#FAAD14",
            }}
          >
            Đã đóng
          </span>
        );
      },
    },
    {
      title: "Hành động",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (val, record) => {
        if (val !== "doing") return null;

        return (
          <Button
            onClick={() => {
              handleClosePackService(
                dataView.data.id,
                _.get(record, "_id", "")
              );
            }}
            type="link"
          >
            Đóng
          </Button>
        );
      },
    },
  ];

  function handleClosePackService(
    contractId: string,
    contractDetailId: string
  ) {
    const payload: PayloadType = {
      params: contractId,
      query: queryString.stringify({ contractDetailId }),

      callback: {
        success(values) {
          notification.success({
            message: "Đóng gói thuê bao thành công!",
          });

          setDataRecord(values.data);
          if (dataView.getContractByStatuss) dataView.getContractByStatuss();
        },
        failed(errorMessage) {
          notification.error({
            message: "Đóng gói thuê bao thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(closePackService(payload));
  }

  const showChildModal = () => {
    setIsChildModalVisible(true);
  };

  const handleChildModalCancel = () => {
    setIsChildModalVisible(false);
  };

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Button type="primary" onClick={showChildModal}>
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.reverse(
          _.cloneDeep(_.get(dataRecord, "contractDetails", []))
        ).slice((query.page - 1) * query.limit, query.page * query.limit)}
        rowClassName={(record: any) =>
          record?.status === "doing" ? "table-row-open" : "table-row-close"
        }
        pagination={{
          total: _.get(dataRecord, "contractDetails", []).length,
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              setQuery((prev) => ({
                ...prev,
                page: 1,
                limit: pageSize,
              }));
              return;
            }

            setQuery((prev) => ({
              ...prev,
              page,
              limit: pageSize,
            }));
          },
        }}
        scroll={{ x: 1300 }}
      />
      {/* Modal con */}
      <Modal
        title="Thêm mới gói dịch vụ"
        centered
        open={isChildModalVisible}
        bodyStyle={{
          padding: 0,
        }}
        onCancel={handleChildModalCancel}
        footer={null} // Nếu cần footer, có thể tuỳ chỉnh lại
      >
        <CUContract
          onClose={handleChildModalCancel}
          onUpdate={handleUpdateDataRecord}
          activeKey={dataView?.activeKey}
          isAddServicePack={true}
          data={dataRecord}
          getContractByStatuss={dataView.getContractByStatuss}
        />
      </Modal>
    </>
  );
}

export default Service;
