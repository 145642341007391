/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { MaleGrowthStandardTemp } from "type";

/*
 * Declare type of data
 */
interface GrowthStandartTempState {
  growthStandardTemps: MaleGrowthStandardTemp[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: GrowthStandartTempState = {
  growthStandardTemps: [],
  isLoading: false,
  query: {
    page: 1,
    populate: "",
    limit: 10,
  },
};

/**
 *  Slice manager service pack
 **/

export const growthStandardTempSlice: any = createSlice({
  name: "growthStandardTempSlice",
  initialState: initialState,
  reducers: {
    getGrowthStandardTemps: (state, action) => {
      state.isLoading = true;
    },
    getGrowthStandardTempSuccess: (state, action) => {
      state.growthStandardTemps = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createGrowthStandardTemp: (state, action) => {
      state.isLoading = true;
    },
    updateGrowthStandardTemp: (state, action) => {
      state.isLoading = true;
    },
    deleteGrowthStandardTemp: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  createGrowthStandardTemp,
  updateGrowthStandardTemp,
  deleteGrowthStandardTemp,
  getGrowthStandardTemps,
  getGrowthStandardTempSuccess,
  setQuery,
} = growthStandardTempSlice.actions;
export default growthStandardTempSlice.reducer;
