import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Select,
  notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { PayloadType, SelectOptionType, UserType } from "type";
import { closeModal, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import _ from "lodash";
import {
  createServicePackages,
  updateServicePackages,
  getServicePackages,
} from "features/servicePackageSlice";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { getSharedDatas } from "features/sharedDataSlice";
import { getTypeGroups } from "features/typeDataSharedSlice";
import { OTHER_SERVICE } from "const";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function CUService({ idUpdate = undefined, record = undefined }: any) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const isServicePackSupport = Form.useWatch("isServicePackSupport", form);

  const { query } = useSelector((state: any) => state.servicePackageReducer);
  const { open } = useSelector((state: any) => state.modalReducer);

  const [listFarmScaleN, setListFarmScaleN] = useState<SelectOptionType[]>([]);
  const [listFarmScaleT, setListFarmScaleT] = useState<SelectOptionType[]>([]);
  // const [isServicePackSupport, setIsServicePackSupport] = useState("");
  // lấy danh sách quy mô trang trại
  const getFieldFarmScaleN = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        code: "QMN",
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          const typeGroupId = _.get(results, "[0].id", "");
          const payload: PayloadType = {
            query: queryString.stringify({
              page: 1,
              limit: 999,
              typeGroupId: typeGroupId,
            }),
            skipUpdateReducer: true,
            callback: {
              success(listScales) {
                const results = _.get(listScales, "results", []);
                if (!!results && Array.isArray(results)) {
                  setListFarmScaleN(
                    results.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })
                  );
                }
              },
              failed(errorMessage) {},
            },
          };
          dispatch(getSharedDatas(payload));
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getTypeGroups(payload));
  };
  const getFieldFarmScaleT = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        code: "QMT",
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          const typeGroupId = _.get(results, "[0].id", "");
          const payload: PayloadType = {
            query: queryString.stringify({
              page: 1,
              limit: 999,
              typeGroupId: typeGroupId,
            }),
            skipUpdateReducer: true,
            callback: {
              success(listScales) {
                const results = _.get(listScales, "results", []);
                if (!!results && Array.isArray(results)) {
                  setListFarmScaleT(
                    results.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })
                  );
                }
              },
              failed(errorMessage) {},
            },
          };
          dispatch(getSharedDatas(payload));
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getTypeGroups(payload));
  };

  useEffect(() => {
    getFieldFarmScaleN();
    if (!_.isEmpty(listFarmScaleN)) {
      getFieldFarmScaleT();
    }
  }, [listFarmScaleN]);
  useEffect(() => {
    if (!open) {
      form.resetFields();
    } else {
      form.setFieldValue("name", _.get(record, "name", ""));
      form.setFieldValue("price", _.get(record, "price", ""));
      form.setFieldValue(
        "otherService",
        (_.get(record, "otherServices", []) as any).includes(
          OTHER_SERVICE.IMPORT
        )
      );
      form.setFieldValue(
        "isServicePackSupport",
        _.get(record, "isServicePackSupport", false)
      );
      form.setFieldValue(
        "farmScaleTTTId",
        _.get(record, "farmScaleTTTId.id", undefined)
      );
      form.setFieldValue(
        "farmScaleTTNId",
        _.get(record, "farmScaleTTNId.id", undefined)
      );
      form.setFieldValue(
        "codeOfCHPlay",
        _.get(record, "codeOfCHPlay", undefined)
      );
      form.setFieldValue(
        "codeOfAppStore",
        _.get(record, "codeOfAppStore", undefined)
      );
    }
  }, [record, open]);

  //Lấy danh sách tài khoản
  function handleGetServicePacks() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          sortBy: "createdAt:desc",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getServicePackages(payload));
  }
  const formSchema = yup.object().shape({
    name: yup.string().required("Vui lòng nhập tên gói dịch vụ!"),
    // time: yup
    //   .number()
    //   .typeError("Vui lòng nhập số tháng !")
    //   .required("Vui lòng nhập số tháng !")
    //   .min(1, "Số tháng phải là số dương và lớn hơn 0"),
    price: yup
      .number()
      .typeError("Vui lòng nhập số tiền !")
      .required("Vui lòng nhập số tiền !")
      .min(0, "Số tiền là số dương và lớn hơn hoặc bằng 0 "),
    farmScaleTTTId: yup.string().required("Vui lòng chọn quy mô tổng số thịt!"),
    farmScaleTTNId: yup.string().required("Vui lòng chọn quy mô tổng số nái!"),
  });
  const yupSync = [getYupSync(formSchema)];
  function handleSubmit() {
    const values = form.getFieldsValue([
      "name",
      "price",
      "farmScaleTTTId",
      "farmScaleTTNId",
      "codeOfCHPlay",
      "codeOfAppStore",
      "isServicePackSupport",
      "otherService",
    ]);
    const body = {
      ...values,
      otherServices: values.otherService ? [OTHER_SERVICE.IMPORT] : [],
    };
    delete body.otherService;

    if (!idUpdate) {
      handleCreateServicePacks(body);
      return;
    }
    handleUpdateListPacks(body);
  }
  function handleCreateServicePacks(values: any) {
    const payload: PayloadType = {
      body: {
        ...values,
      },
      callback: {
        success() {
          notification.success({
            message: "Tạo mới gói dịch vụ thành công!",
          });
          handleGetServicePacks();
          closeModal(dispatch, setModal);
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể tạo mới gói dịch vụ!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createServicePackages(payload));
  }
  function handleUpdateListPacks(values: UserType) {
    const payload: PayloadType = {
      params: idUpdate,
      body: {
        ...values,
      },

      callback: {
        success() {
          notification.success({
            message: "Cập nhật thông tin gói dịch vụ thành công!",
          });
          handleGetServicePacks();
          closeModal(dispatch, setModal);
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể cập nhật thông tin gói dịch vụ!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateServicePackages(payload));
  }

  const initValues = {
    name: undefined,
    farmScaleTTTId: undefined,
    farmScaleTTNId: undefined,
    price: undefined,
    codeOfCHPlay: undefined,
    codeOfAppStore: undefined,
    isServicePackSupport: false,
    otherService: false,
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={initValues}
      autoComplete="off"
    >
      <div className="ant-modal-body">
        <Form.Item required label={"Loại gói"} name={"isServicePackSupport"}>
          <Radio.Group
            onChange={(e) => {
              if (e.target.value === true) {
                form.setFieldValue("otherService", false);
              }
            }}
          >
            <Radio disabled={!!idUpdate} value={false}>
              Thuê bao
            </Radio>
            <Radio disabled={!!idUpdate} value={true}>
              Hỗ trợ
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          required
          label={"Tên gói thuê bao"}
          name={"name"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào tên gói thuê bao" />
        </Form.Item>
        <Form.Item
          required
          label={
            <div
              style={{
                textAlign: "right",
                lineHeight: "15px",
                marginTop: "-3px",
              }}
            >
              Quy mô tổng số nái
            </div>
          }
          name={"farmScaleTTNId"}
          rules={yupSync}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Chọn quy mô"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            allowClear
            options={listFarmScaleN}
          />
        </Form.Item>

        <Form.Item
          required
          label={
            <div
              style={{
                textAlign: "right",
                lineHeight: "15px",
                marginTop: "-3px",
              }}
            >
              Quy mô tổng số thịt
            </div>
          }
          name={"farmScaleTTTId"}
          rules={yupSync}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Chọn quy mô"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            allowClear
            options={listFarmScaleT}
          />
        </Form.Item>
        {!isServicePackSupport && (
          <Form.Item
            label={"Dịch vụ khác"}
            name="otherService"
            valuePropName="checked"
          >
            <Checkbox>Dịch vụ nhập liệu</Checkbox>
          </Form.Item>
        )}
        {/* <Form.Item
          required
          label={"Thời gian (tháng)"}
          name={"time"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào số tháng" />
        </Form.Item> */}

        <Form.Item
          required
          label={"Giá tiền(vnđ)"}
          name={"price"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào số tiền" />
        </Form.Item>

        <Form.Item label={"Mã gói CH Play"} name={"codeOfCHPlay"}>
          <Input placeholder="Nhập vào mã gói" />
        </Form.Item>

        <Form.Item label={"Mã gói AppStore"} name={"codeOfAppStore"}>
          <Input placeholder="Nhập vào mã gói" />
        </Form.Item>
        {/* <Form.Item label={"Chi tiết chi phí "}> */}
        {/* <CKEditor
            editor={ClassicEditor}
            data=""
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditorData(data);
            }}
          /> */}
        {/* </Form.Item> */}
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
        >
          {idUpdate ? "Lưu lại" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUService;
