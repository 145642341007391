import { Form, Button, Select, Layout, Input, Space, notification } from "antd";
import _ from "lodash";
const { Content } = Layout;
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { closeModal, getYupSync } from "utils";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { PayloadType, UserType } from "type";
import * as yup from "yup";
import ServiceURL from "services/ServiceURL";
import { GET } from "services/ServiceBase";
import { useForm } from "antd/lib/form/Form";
import { getContractByStatus, updateSupport } from "features/contractSlice";

const { TextArea } = Input;

function Support(dataView: any) {
  const [loading, setLoading] = useState(false);
  const [userTeachData, setUsserTeachData] = useState([]);
  const [userSupportData, setUsserSupportData] = useState([]);

  const record = dataView?.data;
  const dispatch = useDispatch();
  async function getUsersSupport(queryUser: any) {
    const url = `${ServiceURL.user}?${queryString.stringify(queryUser) ?? ""}`;
    try {
      const res = await GET(url);

      setUsserSupportData(_.get(res, "data", []));
    } catch (error: any) {
      console.log(error);
    }
  }
  async function getUsersTeach(queryUser: any) {
    const url = `${ServiceURL.user}?${queryString.stringify(queryUser) ?? ""}`;
    try {
      const res = await GET(url);
      setUsserTeachData(_.get(res, "data", []));
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUsersSupport({ accountType: "support" });
    getUsersTeach({ accountType: "tech" });
  }, []);

  const handleSearch = _.debounce((value: string) => {
    const query = { name: value };
    // Gọi API với giá trị tìm kiếm
    getUsersTeach(query);
  }, 300);

  const [form] = useForm();

  function handleSubmit() {
    const values = form.getFieldsValue([
      "techSupporter",
      "dataEntryClerk",
      "note",
    ]);

    handleUpdateSupport(values);
  }

  function handleUpdateSupport(values: UserType) {
    setLoading(true);
    const payload: PayloadType = {
      params: dataView.data.id,
      body: values,
      callback: {
        success() {
          notification.success({
            message: "Cập nhật thành công!",
          });

          if (dataView.getContractByStatuss) dataView.getContractByStatuss();
        },
        failed(errorMessage) {
          notification.error({
            message: "Cập nhật thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateSupport(payload));
  }

  useEffect(() => {
    form.setFieldValue(
      "dataEntryClerk",
      _.get(record, "dataEntryClerk.id", undefined)
    );
    form.setFieldValue(
      "techSupporter",
      _.get(record, "techSupporter.id", undefined)
    );
    form.setFieldValue("note", _.get(record, "note", undefined));
  }, [record]);
  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 20,
        marginTop: 16,
        marginRight: 20,
        // backgroundColor: "white",
      }}
    >
      <Form
        labelCol={{
          span: 4,
        }}
        onFinish={() => {
          handleSubmit();
        }}
        form={form}
        layout="horizontal"
        autoComplete="off"
      >
        <Form.Item
          label={"Hỗ trợ kỹ thuật :"}
          name={"techSupporter"}
          //  rules={yupSync}
        >
          <Select
            showSearch
            allowClear
            filterOption={false}
            onSearch={handleSearch}
            options={_.get(userTeachData, "results", []).map((item: any) => ({
              label: item?.name,
              value: item?.id,
            }))}
            style={{ width: "30%" }}
            placeholder="Chọn hỗ trợ kỹ thuật"
          />
        </Form.Item>

        <Form.Item
          label={"Hỗ trợ nhập liệu :"}
          name={"dataEntryClerk"}
          //  rules={yupSync}
        >
          <Select
            showSearch
            allowClear
            onSearch={handleSearch}
            filterOption={false}
            options={_.get(userSupportData, "results", []).map((item: any) => ({
              label: item?.name,
              value: item?.id,
            }))}
            style={{ width: "30%" }}
            placeholder="Chọn hỗ trợ nhập liệu"
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label={"Ghi chú :"}
          name={"note"}
          //  rules={yupSync}
        >
          <TextArea
            allowClear
            style={{ width: "30%" }}
            placeholder="Nhập ghi chú"
          />
        </Form.Item>
      </Form>
      <div className="ant-modal-footer">
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
          // loading={loading}
        >
          Lưu lại
        </Button>
      </div>
    </Content>
  );
}

export default Support;
