/**
 * Màn hình hiển thị danh sách thông số
 */
import { useEffect } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Typography,
  notification,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import {
  ModalInfoType,
  PayloadType,
  ParameterType,
  ActionGroup,
  Action,
  AddData,
} from "type";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "features/modalSlice";
import CUParameter from "pages/Parameter/ListParameter/CUParameter";
import _, { isNull } from "lodash";
import { getParameters, setQuery } from "features/parameterSlice";
import queryString from "query-string";
import { closeModal } from "utils";

const { Title } = Typography;

function ListProcess() {
  const dispatch = useDispatch();
  const { query, parameters } = useSelector(
    (state: any) => state.parameterReducer
  );

  //Lấy danh sách thông số
  function handleGetParameter() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          populate: "actionIds",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getParameters(payload));
  }
  useEffect(() => {
    handleGetParameter();
    getActionsFromParametters(parameters);
  }, [query]);

  const columns: ColumnsType<AddData> = [
    {
      title: "Tên thông số",
      dataIndex: "name",
      key: "name",
      width: 900,
      align: "left",
    },
    {
      title: "Giá trị min",
      dataIndex: ["param", "minValue"],
      key: "param.value",
      width: 100,
      align: "center",
      render: (value) => {
        return (
          <div style={{ textAlign: "right" }}>{`${
            _.isUndefined(value) || isNull(value) ? "-" : value
          }`}</div>
        );
      },
    },

    {
      title: "Giá trị max",
      dataIndex: ["param", "maxValue"],
      key: "param.value",
      width: 100,
      align: "center",
      render: (value) => {
        return (
          <div style={{ textAlign: "right" }}>{`${
            _.isUndefined(value) || isNull(value) ? "-" : value
          }`}</div>
        );
      },
    },

    {
      title: "Đơn vị",
      dataIndex: "unitId",
      key: "unitId",
      width: 100,
      align: "center",
    },
  ];

  const getActionsFromParametters = (data: {
    results: ActionGroup[];
  }): AddData[] => {
    return (data?.results ?? [])
      .map((item: ActionGroup) => {
        return (item?.actionIds ?? [])
          .map((action: Action): AddData[] => {
            return (action?.addData ?? [])
              .map((aD: AddData): AddData | null => {
                const isUse = _.get(aD, "param.isUse", false);
                const canConfig = _.get(aD, "param.canConfig", false);
                if (isUse && canConfig) {
                  return {
                    ...aD,
                    actionId: action?.id,
                    actionGroupId: item?.id,
                  };
                }
                return null;
              })
              .filter(notNull);
          })
          .flat();
      })
      .flat();
  };

  function notNull<T>(val: T | null): val is T {
    return val !== null;
  }


  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={getActionsFromParametters(parameters)}
        scroll={{ x: 1300 }}
        onRow={(record: any) => ({
          onClick: () => {
            const payload: ModalInfoType = {
              open: true,
              onClose: () => {
                const payload: PayloadType = {
                  params: _.get(record, "id"),
                  body: {
                    status: true,
                  },
                  callback: {
                    success() {
                      handleGetParameter();
                      closeModal(dispatch, setModal);
                    },
                    failed(errorMessage) {
                      notification.error({
                        message: "Có lỗi xảy ra!",
                        description: errorMessage,
                      });
                    },
                  },
                };
              },
              title: "Sửa thông số",
              content: <CUParameter record={record} />,
              size: 600,
            };
            dispatch(setModal(payload));
          },
        })}
        pagination={{
          total: getActionsFromParametters(parameters).length,
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          pageSizeOptions: ["10", "20", "50", "100"], // Các tùy chọn cho số bản ghi/trang
          defaultPageSize: 10, // Số bản ghi mặc định mỗi trang
          showSizeChanger: true,
        }}
      />
    </>
  );
}

export default ListProcess;
