/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { Contract } from "type";

/*
 * Declare type of data
 */
interface ContractState {
  contracts: Contract[]; //
  countContract: any;
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: ContractState = {
  contracts: [],
  countContract: {
    trial: 0,
    doing: 0,
    stop: 0,
  },
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager contract
 **/

export const contractSlice: any = createSlice({
  name: "contractSlice",
  initialState: initialState,
  reducers: {
    getContracts: (state, action) => {
      state.isLoading = true;
      state.countContract = action.payload;
    },

    countContracts: (state, action) => {
      state.isLoading = true;
    },

    getContractsSuccess: (state, action) => {
      state.contracts = action.payload;
      state.isLoading = false;
    },

    countContractsSuccess: (state, action) => {
      state.countContract = action.payload;
      state.isLoading = false;
    },
    getContractByStatus: (state, action) => {
      state.isLoading = true;
    },

    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createContracts: (state, action) => {
      state.isLoading = true;
    },
    caculateContracts: (state, action) => {
      state.isLoading = true;
    },

    updateContracts: (state, action) => {
      state.isLoading = true;
    },
    deleteContracts: (state, action) => {
      state.isLoading = true;
    },
    closePackService: (state, action) => {},
    addPackService: (state, action) => {},
    updateSupport: (state, action) => {},
  },
});
export const {
  countContracts,
  createContracts,
  caculateContracts,
  updateContracts,
  deleteContracts,
  getContracts,
  countContractsSuccess,
  getContractByStatus,
  getContractsSuccess,
  setQuery,
  closePackService,
  addPackService,
  updateSupport,
} = contractSlice.actions;
export default contractSlice.reducer;
