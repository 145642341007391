/**
 * Tab Nhóm khác
 */
import { useEffect } from "react";

import { Button, Popconfirm, Table, Typography, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { Space } from "antd";
import { ModalInfoType, PayloadType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  getServicePackages,
  deleteServicePackages,
  setQuery,
} from "features/servicePackageSlice";
import queryString from "query-string";
import CUService from "./CUService";
import { OTHER_SERVICE } from "const";
const { Title } = Typography;
interface DataType {
  phone: string;
  name: string;
  nameForPig: string;
  farmName: string;
  farmTypeId: string;
  farmScaleId: string;
  email: string;
  personInChargeId: string;
  address: string;
  provinceId: string;
  servicePackageId: string;
  serviceTimeId: string;
}

function ListService() {
  const dispatch = useDispatch();
  const { query, servicePackages } = useSelector(
    (state: any) => state.servicePackageReducer
  );

  //Lấy danh sách gói dịch vụ
  function handleGetServicePacks() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          sortBy: "createdAt:desc",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getServicePackages(payload));
  }
  useEffect(() => {
    handleGetServicePacks();
  }, [query]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Tên gói thuê bao",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Loại gói",
      dataIndex: "isServicePackSupport",
      key: "isServicePackSupport",
      align: "center",
      render: (value) => {
        if (value === true) {
          return "Hỗ trợ";
        }
        return "Thuê bao";
      },
    },
    {
      title: "Quy mô tổng số nái",
      dataIndex: ["farmScaleTTNId", "name"],
      key: "farmScaleTTNId.name",
      align: "left",
    },
    {
      title: "Quy mô tổng số thịt",
      dataIndex: ["farmScaleTTTId", "name"],
      key: "farmScaleTTTId.name",
      align: "left",
    },

    {
      title: "Dịch vụ nhập liệu",
      dataIndex: "otherServices",
      key: "otherServices",
      align: "center",
      render: (value, row) => {
        if (
          value.includes(OTHER_SERVICE.IMPORT) &&
          !_.get(row, "isServicePackSupport")
        ) {
          return (
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 1.5C6.70156 1.5 2 6.20156 2 12C2 17.7984 6.70156 22.5 12.5 22.5C18.2984 22.5 23 17.7984 23 12C23 6.20156 18.2984 1.5 12.5 1.5ZM17.0352 8.57109L12.0992 15.4148C12.0302 15.5111 11.9393 15.5896 11.8339 15.6437C11.7286 15.6978 11.6118 15.7261 11.4934 15.7261C11.3749 15.7261 11.2582 15.6978 11.1528 15.6437C11.0474 15.5896 10.9565 15.5111 10.8875 15.4148L7.96484 11.3648C7.87578 11.2406 7.96484 11.0672 8.11719 11.0672H9.21641C9.45547 11.0672 9.68281 11.182 9.82344 11.3789L11.4922 13.6945L15.1766 8.58516C15.3172 8.39062 15.5422 8.27344 15.7836 8.27344H16.8828C17.0352 8.27344 17.1242 8.44688 17.0352 8.57109Z"
                fill="#389E0D"
              />
            </svg>
          );
        }
        return null;
      },
    },
    {
      title: "Giá tiền (vnđ)",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                record = {
                  ...record,
                };

                const payload: ModalInfoType = {
                  open: true,
                  title: "Sửa gói dịch vụ",
                  content: (
                    <CUService idUpdate={record?.id ?? false} record={record} />
                  ),
                  size: 600,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa mục này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id;
                const payload: PayloadType = {
                  params: id,
                  callback: {
                    success(values) {
                      notification.success({
                        message: "Xoá nhóm gói dịch vụ thành công!",
                      });
                      handleGetServicePacks();
                    },
                    failed(errorMessage) {
                      notification.error({
                        message: "Xoá nhóm gói dịch vụ thất bại!",
                        description: errorMessage,
                      });
                    },
                  },
                };
                dispatch(deleteServicePackages(payload));
              }}
            >
              <a>Xoá</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới gói thuê bao",
                content: <CUService idUpdate={undefined} />,
                size: 600,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(servicePackages, "results", [])}
        pagination={{
          total: _.get(servicePackages, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default ListService;
