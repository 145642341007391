import accountReducer from "features/accountSlice";
import userReducer from "features/userSlice";
import roleReducer from "features/roleSlice";
import provinceReducer from "features/provinceSlice";
import districtReducer from "features/districtSlice";
import awardReducer from "features/awardSlice";
import modalReducer from "features/modalSlice";

//DTA
import typeDataSharedReducer from "./typeDataSharedSlice";
import sharedDataReducer from "./sharedDataSlice";
import configReducer from "features/configSlice";
import feedBackReducer from "features/feedBackSlice";
import subscriberReducer from "features/subscriberSlice";
import servicePackageReducer from "features/servicePackageSlice";
import promotionReducer from "features/promotionsSlice";
import productReducer from "features/productSlice";
import productTempReducer from "features/productTempSlice";
import promotionDetailReducer from "features/promotionDetailSlice";
import parameterReducer from "features/parameterSlice";
import typeGroupReducer from "features/typeGroupSlice";
import procedureReducer from "features/procedureSlice";
import herdTypeReducer from "features/herdTypeSlice";
import PigReducer from "features/pigSlice";
import contractReducer from "features/contractSlice";
import herdReducer from "features/herdSlice";
import StatisticalReducer from "features/statisticalSlice";
import ImportReducer from "features/importSlice";
import pigTypeSlice from "./pigTypeSlice";
import growthStandardTempReducer from "./growthStandardTempSlice";

export const reducer = {
  accountReducer,
  userReducer,
  roleReducer,
  provinceReducer,
  districtReducer,
  awardReducer,
  modalReducer,
  herdReducer,
  typeDataSharedReducer,
  sharedDataReducer,
  configReducer,
  feedBackReducer,
  subscriberReducer,
  servicePackageReducer,
  promotionReducer,
  promotionDetailReducer,
  parameterReducer,
  typeGroupReducer,
  herdTypeReducer,
  procedureReducer,
  contractReducer,
  productTempReducer,
  productReducer,
  StatisticalReducer,
  ImportReducer,
  pigTypeSlice,
  PigReducer,
  growthStandardTempReducer,
};
