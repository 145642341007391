/**
 * Tab Nhóm khác
 */
import { Tabs } from "antd";

import {} from "antd";

import _ from "lodash";

import GeneralInfo from "./tabRContract/GeneralInfo";
import Service from "./tabRContract/Service";
import Support from "./tabRContract/Support";
function RContract(dataView: any) {
  const record = dataView?.record;

  //Lấy danh sách chi tiết khuyến mãi theo id khuyến mãi

  const optionTabContracts = [
    {
      key: `1`,
      label: `Thông tin chung`,
      children: <GeneralInfo data={record} />,
    },
    {
      key: `2`,
      label: `Thuê bao`,
      children: (
        <Service
          data={record}
          activeKey={dataView?.activeKey}
          getContractByStatuss={dataView.getContractByStatuss}
        />
      ),
    },

    {
      key: `4`,
      label: `Hỗ trợ`,
      children: (
        <Support
          data={record}
          activeKey={dataView?.activeKey}
          getContractByStatuss={dataView.getContractByStatuss}
        />
      ),
    },
  ];
  return (
    //
    <div style={{ padding: 20 }}>
      <Tabs defaultActiveKey="1" items={optionTabContracts} />
    </div>
  );
}

export default RContract;
