import React, { FunctionComponent } from "react";
import Login from "pages/Login";
//icon
import ResetPassword from "pages/ResetPassword";
import {
  UserOutlined,
  ApartmentOutlined,
  SettingOutlined,
  HomeOutlined,
  TeamOutlined,
  PropertySafetyOutlined,
  CreditCardOutlined,
  TagOutlined,
  SnippetsOutlined,
  ProjectOutlined,
  SlidersOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import ListDataShared from "pages/ListDataShared";
import Feedback from "pages/Feedback";
import TypeDataShared from "pages/TypeDataShared";
import AdminUser from "pages/AdminUser";
import User from "pages/User";
import Process from "pages/Process";
import Parameter from "pages/Parameter";
import Service from "pages/Service";
import Promotion from "pages/Promotion";
import RPromotion from "pages/Promotion/ListPromotion/RPromotion";
import PromotionDetail from "pages/PromotionDetail";
import PigFarm from "pages/PigFarm";
import RPigFarm from "pages/PigFarm/ListPigFarm/RPigFarm";
import Contract from "pages/Contract";
import RContract from "pages/Contract/ListContract/RContract";
import Medicine from "pages/Medicine";
import Sick from "pages/Sick";
import Food from "pages/Food";
import PigFarmImport from "pages/PigFarmImport";
import GrowthStandard from "pages/GrowthStandardTemp";

interface RouteItemType {
  hidden?: boolean; //VD: Ẩn các route cho màn thêm, sửa
  collapse?: boolean;
  state?: string; // các route sẽ nhớ và active lại khi f5: [state,path+name]
  name?: string;
  path?: string;
  icon?: any;
  component?: FunctionComponent;
  layout?: string;
  roles?: [];
  views?: any[]; // yêu cầu collapse = true
  questionType?: string;
  answerType?: string;
}

// Route Auth
const routesA = [
  {
    collapse: true,
    name: "Auth",
    state: "authCollapse",
    views: [
      {
        path: "/login",
        name: "Đăng nhập",
        miniName: "L",
        component: Login,
        layout: "/auth",
        roles: [],
      },
      {
        path: "/reset-password",
        name: "Quên mật khẩu",
        miniName: "R",
        component: ResetPassword,
        layout: "/auth",
        roles: [],
      },
    ],
  },
];
const routesSM: RouteItemType[] = [
  {
    collapse: false,
    name: "Tổng quan",
    path: "/dashboard",
    icon: React.createElement(HomeOutlined),
    layout: "/admin",
    component: Contract,
  },
  {
    collapse: true,
    name: "Quản lý DL dùng chung",
    icon: React.createElement(ApartmentOutlined),
    state: "TCollapse",
    views: [
      {
        path: "/list",
        name: "Danh sách",
        component: ListDataShared,
        layout: "/admin",
        roles: [],
      },
      {
        path: "/type",
        name: "Nhóm thể loại",
        component: TypeDataShared,
        layout: "/admin",
        roles: [],
      },

      {
        path: "/food",
        name: "Tên thức ăn",
        component: Food,
        layout: "/admin",
        roles: [],
      },

      {
        path: "/medicine",
        name: "Tên thuốc",
        component: Medicine,
        layout: "/admin",
        roles: [],
      },
      {
        path: "/sick",
        name: "Tên bệnh",
        component: Sick,
        layout: "/admin",
        roles: [],
      },
    ],
  },

  {
    collapse: true,
    name: "Quản trị trang trại",
    icon: React.createElement(TeamOutlined),
    state: "TCollapse2",
    views: [
      {
        path: "/user",
        name: "Danh sách tài khoản",
        component: User,
        layout: "/admin",
        roles: [],
      },
      {
        path: "/pig-farm3",
        name: "Danh sách trại (KT)",
        component: PigFarm,
        layout: "/admin",
        roles: [],
      },
      {
        path: "/pig-farm4",
        name: "Danh sách trại (Nhập liệu )",
        component: PigFarmImport,
        layout: "/admin",
        roles: [],
      },
      {
        path: "/contracts",
        name: "Danh sách hợp đồng",
        component: Contract,
        layout: "/admin",
        roles: [],
      },
    ],
  },

  // {
  //   collapse: false,
  //   path: "/contract",
  //   name: "Quản lý hợp đồng khách hàng",
  //   icon: React.createElement(SnippetsOutlined),
  //   component: Contract,
  //   layout: "/admin",
  // },

  {
    collapse: false,
    path: "/admin-user",
    name: "Quản trị tài khoản Admin",
    icon: React.createElement(UserOutlined),
    component: AdminUser,
    layout: "/admin",
  },

  {
    collapse: false,
    path: "/process",
    name: "Quản trị quy trình",
    icon: React.createElement(ProjectOutlined),
    component: Process,
    layout: "/admin",
  },

  {
    collapse: false,
    path: "/growth-standard",
    name: "Quản trị trọng lượng TB",
    icon: React.createElement(SlidersOutlined),
    component: GrowthStandard,
    layout: "/admin",
  },
  {
    collapse: false,
    path: "/parameter",
    name: "Quản trị thông số",
    icon: React.createElement(SlidersOutlined),
    component: Parameter,
    layout: "/admin",
  },

  // {
  //   collapse: false,
  //   path: "/role",
  //   name: "Quản trị quyền",
  //   icon: React.createElement(PropertySafetyOutlined),
  //   layout: "/admin",
  // },
  {
    collapse: false,
    path: "/service",
    name: "Quản trị gói thuê bao",
    icon: React.createElement(CreditCardOutlined),
    component: Service,
    layout: "/admin",
  },

  {
    collapse: true,
    name: "Quản trị khuyến mãi",
    icon: React.createElement(TagOutlined),
    state: "TCollapse1",
    views: [
      {
        path: "/promotion",
        name: "Chương trình khuyến mãi",
        component: Promotion,
        layout: "/admin",
        roles: [],
      },
      {
        path: "/promotion-detail",
        name: "Chi tiết khuyến mãi",
        component: PromotionDetail,
        layout: "/admin",
        roles: [],
      },
    ],
  },
  {
    hidden: true,
    collapse: false,
    path: "/promotion-info/:id",
    name: "Chi tiết chương trình khuyến mãi",
    icon: React.createElement(CustomerServiceOutlined),
    component: RPromotion,
    layout: "/admin",
  },
  {
    hidden: true,
    collapse: false,
    path: "/pig-farm-info/:id",
    name: "Chi tiết trại người dùng",
    icon: React.createElement(CustomerServiceOutlined),
    component: RPigFarm,
    layout: "/admin",
  },
  {
    hidden: true,
    collapse: false,
    path: "/contract-info/:id",
    name: "Chi tiết hợp đồng khách hàng",
    icon: React.createElement(CustomerServiceOutlined),
    component: RContract,
    layout: "/admin",
  },
];

export default {
  routesA,
  routesSM,
};
