import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  notification,
  DatePicker,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { PayloadType, PromotionType } from "type";
import { closeModal, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import type { RangePickerProps } from "antd/es/date-picker";
import { getRoles } from "features/roleSlice";
import dayjs from "dayjs";

import moment from "moment";
import _ from "lodash";
import queryString from "query-string";
import { HINH_THUC } from "const";
import { useSelector } from "react-redux";
import {
  getPromotions,
  createPromotions,
  updatePromotions,
} from "features/promotionsSlice";

const dateFormat = "DD/MM/YYYY";
function CUPromotion({ idUpdate = undefined, record = undefined }: any) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.userReducer);
  const [loading, setLoading] = useState(false);
  const { open } = useSelector((state: any) => state.modalReducer);
  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
    if (record) {
      form.setFieldValue("form", _.get(record, "form", null));
      form.setFieldValue("name", _.get(record, "name", null));
      form.setFieldValue("status", _.get(record, "status", null));
      form.setFieldValue("value", _.get(record, "value", null));
      form.setFieldValue("startDate", moment(_.get(record, "startDate", "")));
      form.setFieldValue("endDate", moment(_.get(record, "endDate", "")));
    } else {
      form.setFieldsValue({
        name: null,
        form: null,
        status: null,
        startDate: null,
        endDate: null,
        value: null,
      });
    }
  }, [record, open]);
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current <= dayjs().endOf("day");
  };

  //Lấy danh sách chương trình khuyến mãi
  function handleGetPromotions() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          sortBy: "createdAt:desc",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotions(payload));
  }
  const formSchema = yup.object().shape({
    name: yup.string().required("Vui lòng nhập chương trình khuyến mãi!"),
    form: yup.string().required("Vui lòng chọn hình thức khuyến mãi!"),
    value: yup
      .number()
      .typeError("Vui lòng nhập giá trị !")
      .required("Vui lòng nhập giá trị !")
      .min(1, "Giá trị là số dương và lớn hơn 0")
      .test("validate", "Phần trăm phải thuộc khoảng từ 0-100", () => {
        if (form.getFieldValue("form") == "percent") {
          return (
            parseInt(form.getFieldValue("value")) > 0 &&
            parseInt(form.getFieldValue("value")) < 100
          );
        } else {
          return true;
        }
      }),
    startDate: yup.date().required("Vui lòng nhập ngày bắt đầu!"),
    endDate: yup
      .date()
      .required("Vui lòng nhập ngày kết thúc!")
      .test("test-date", "Ngày kết thúc phải lớn hơn ngày bắt đầu", () => {
        const startDateValue = form.getFieldValue("startDate");
        const endDateValue = form.getFieldValue("endDate");
        if (endDateValue && endDateValue.isSameOrAfter(startDateValue)) {
          return true;
        } else {
          return false;
        }
      }),
    status: yup.string().notRequired(),
  });
  const yupSync = [getYupSync(formSchema)];
  function handleSubmit() {
    const values = form.getFieldsValue([
      "form",
      "name",
      "value",
      "endDate",
      "startDate",
      "status",
    ]);
    if (values.endDate < values.startDate) {
      notification.error({
        message: "Ngày kết thúc phải sau ngày bắt đầu!",
      });
      return;
    } else {
      if (!idUpdate) {
        handleCreateUser(values);
        return;
      }
      handleUpdateUser(values);
    }
  }
  function handleCreateUser(values: PromotionType) {
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const payload: PayloadType = {
              body: {
                ...values,
                status: "active",
              },
              callback: {
                success(values) {
                  notification.success({
                    message: "Tạo mới chương trình khuyến mãi thành công!",
                  });
                  handleGetPromotions();
                  closeModal(dispatch, setModal);
                },
                failed(errorMessage) {
                  notification.error({
                    message: "Không thể  tạo mới chương trình khuyến mãi!",
                    description: errorMessage,
                  });
                },
              },
            };
            dispatch(createPromotions(payload));
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể tạo mới chương trình khuyến mãi!",
            description: "Thiếu quyền!",
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }
  function handleUpdateUser(values: PromotionType) {
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const payload: PayloadType = {
              params: idUpdate,
              body: {
                ...values,
              },
              callback: {
                success(values) {
                  notification.success({
                    message:
                      "Cập nhật thông tin chương trình khuyến mãi thành công!",
                  });
                  handleGetPromotions();
                  closeModal(dispatch, setModal);
                },
                failed(errorMessage) {
                  notification.error({
                    message:
                      "Không thể cập nhật thông tin chương trình khuyến mãi!",
                    description: errorMessage,
                  });
                },
              },
            };
            dispatch(updatePromotions(payload));
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể cập nhật thông tin chương trình khuyến mãi!",
            description: "Thiếu quyền!",
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      autoComplete="off"
    >
      <div className="ant-modal-body">
        <Form.Item
          required
          label={"Tên chương trình KM"}
          name={"name"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào tên chương trình" />
        </Form.Item>

        <Form.Item
          required
          label={"Hình thức KM"}
          name={"form"}
          rules={yupSync}
        >
          <Select
            allowClear
            placeholder="Chọn hình thức khuyến mãi"
            style={{ width: "100%" }}
            options={HINH_THUC}
          />
        </Form.Item>
        <Form.Item required label={"Giá trị"} name={"value"} rules={yupSync}>
          <Input placeholder="Nhập vào giá trị" />
        </Form.Item>
        <Form.Item
          required
          label={"Thời gian bắt đầu"}
          name={"startDate"}
          rules={yupSync}
        >
          <DatePicker
            format={dateFormat}
            placeholder="Chọn ngày bắt đầu"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          required
          label={"Thời gian kết thúc"}
          name={"endDate"}
          rules={yupSync}
        >
          <DatePicker
            format={dateFormat}
            placeholder="Chọn ngày kết thúc"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label={"Trạng thái"}
          name={"status"}
          rules={yupSync}
          hidden={idUpdate ? false : true}
          required
        >
          <Radio.Group>
            <Radio value={"active"}>Hoạt động</Radio>
            <Radio value={"lock"}>Đóng</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            if (!loading) form.submit();
          }}
          type="primary"
          loading={loading}
        >
          {idUpdate ? "Lưu lại" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUPromotion;
