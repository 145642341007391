import { Button, Form, Input, Radio, Select, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { PayloadType, SelectOptionType, AdminDataType, UserType } from "type";
import { closeModal, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getRoles } from "features/roleSlice";
import _ from "lodash";
import { createUser, getUser, getUsers, updateUser } from "features/userSlice";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { ACCOUNT_TYPE } from "const";

interface AdminInfo {
  idUpdate?: string;
  record?: AdminDataType;
}
function CUUser({ idUpdate = undefined, record = undefined }: AdminInfo) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.userReducer);
  const [loading, setLoading] = useState(false);
  const { open } = useSelector((state: any) => state.modalReducer);
  const [listRoles, setListRoles] = useState<SelectOptionType[]>([]);
  const roleId = Form.useWatch("roleId", form);
  const roleName = listRoles.find((item) => item.value === roleId)?.label;
  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
    form.setFieldValue("roleId", _.get(record, "roleId.id", undefined));
    form.setFieldValue("phone", _.get(record, "phone", undefined));
    form.setFieldValue("email", _.get(record, "email", undefined));
    form.setFieldValue("status", _.get(record, "status", "active"));
    form.setFieldValue("name", _.get(record, "name", undefined));
    form.setFieldValue("accountType", _.get(record, "accountType", "admin"));
  }, [record, open]);

  //Lấy danh sách tài khoản
  function handleGetUser() {
    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipNull: true,
        skipEmptyString: true,
      }),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getUsers(payload));
  }

  const formSchema = yup.object().shape({
    name: yup.string().required("Họ tên tài khoản không được để trống!"),
    status: yup.string().required("Vui lòng chọn trạng thái tài khoản!"),
    roleId: yup.string().required("Vui lòng chọn nhóm tài khoản!"),
    email: yup
      .string()
      .required("Email không được để trống!")
      .email("Không đúng định dạng email!"),
    phone: yup
      .string()
      .length(10, "Số điện thoại bao gồm 10 số!")
      .required("Tên đăng nhập không được để trống!")
      .matches(
        /0[1-9][0-9]{8}/g,
        "Vui lòng nhập đúng định dạng số điện thoại!"
      ),
    password: yup
      .string()
      .test(
        "conditional-password",
        "Mật khẩu cần chứa ít nhất 8 kí tự, bao gồm chữ và số!",
        function (value) {
          if (_.isUndefined(idUpdate)) {
            // Nếu 'a' tồn tại, kiểm tra mật khẩu theo yêu cầu
            return yup
              .string()
              .required("Vui lòng nhập mật khẩu!")
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                "Mật khẩu cần chứa ít nhất 8 kí tự, bao gồm chữ và số!"
              )
              .isValidSync(value); // Kiểm tra xem mật khẩu có đúng điều kiện không
          }
          // Nếu 'a' không tồn tại, mật khẩu không bắt buộc
          return yup
            .string()
            .notRequired()
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
              "Mật khẩu cần chứa ít nhất 8 kí tự, bao gồm chữ và số!"
            )
            .isValidSync(value); // Kiểm tra xem mật khẩu có đúng điều kiện không
        }
      ),
  });
  const yupSync = [getYupSync(formSchema)];
  function handleSubmit() {
    const values = form.getFieldsValue([
      "name",
      "email",
      "phone",
      "roleId",
      "status",
      "password",
      "accountType",
    ]);
    if (!idUpdate) {
      handleCreateUser(values);
      return;
    }
    handleUpdateUser(values);
  }
  function handleCreateUser(values: UserType) {
    setLoading(true);
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          // permision = [string] bao gồm cac quyền: get, mange(C, R), delete
          if (!!results && _.isArray(results) && results.length > 0) {
            const payload: PayloadType = {
              body: {
                ...values,
              },

              callback: {
                success() {
                  notification.success({
                    message: "Tạo mới Admin thành công!",
                  });
                  handleGetUser();
                  closeModal(dispatch, setModal);
                  setLoading(false);
                },
                failed(errorMessage) {
                  notification.error({
                    message: "Không thể  tạo mới Admin!",
                    description: errorMessage,
                  });
                  setLoading(false);
                },
              },
            };
            dispatch(createUser(payload));
          }
        },
        failed() {
          notification.error({
            message: "Không thể tạo mới Admin!",
            description: "Thiếu quyền!",
          });
          setLoading(false);
        },
      },
    };
    dispatch(getRoles(payload));
  }
  function handleUpdateUser(values: UserType) {
    setLoading(true);
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const payload: PayloadType = {
              params: idUpdate,
              body: {
                ...values,
              },
              callback: {
                success() {
                  notification.success({
                    message: "Cập nhật thông tin Admin thành công!",
                  });
                  handleGetUser();
                  closeModal(dispatch, setModal);
                  setLoading(false);
                },
                failed(errorMessage) {
                  notification.error({
                    message: "Không thể cập nhật thông tin Admin!",
                    description: errorMessage,
                  });
                  setLoading(false);
                },
              },
            };
            dispatch(updateUser(payload));
          }
        },
        failed() {
          notification.error({
            message: "Không thể cập nhật thông tin Admin!",
            description: "Thiếu quyền!",
          });
          setLoading(false);
        },
      },
    };
    dispatch(getRoles(payload));
  }

  //Lấy thông tin tài khoản Admin
  function handleGetUserByID() {
    const payload: PayloadType = {
      params: idUpdate,
      callback: {
        success(user) {
          if (user) {
            form.setFieldsValue(user);
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy thông tin Admin thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getUser(payload));
  }

  // Lấy options cho ô select roles
  const getFieldRoles = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(roleId) {
          const results = _.get(roleId, "results", []);
          if (!!results && Array.isArray(results)) {
            setListRoles(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                  ...item,
                };
              })
            );
          }
        },
        failed() {},
      },
    };
    dispatch(getRoles(payload));
  };

  useEffect(() => {
    getFieldRoles();
  }, []);

  useEffect(() => {
    if (idUpdate) {
      handleGetUserByID();
    }
  }, [idUpdate]);
  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      autoComplete="off"
    >
      <div className="ant-modal-body">
        <Form.Item
          required
          label={"Tên đăng nhập"}
          name={"phone"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào số điện thoại" disabled={!!idUpdate} />
        </Form.Item>

        <Form.Item
          required
          label={"Mật khẩu"}
          name={"password"}
          rules={yupSync}
        >
          <Input
            placeholder={
              idUpdate
                ? "Vui lòng bỏ qua trường này nếu không muốn đổi mật khẩu"
                : "Nhập vào mật khẩu"
            }
          />
        </Form.Item>

        <Form.Item required label={"Họ tên"} name={"name"} rules={yupSync}>
          <Input placeholder="Nhập vào họ tên" />
        </Form.Item>
        <Form.Item required label={"Email"} name={"email"} rules={yupSync}>
          <Input placeholder="Nhập vào email" />
        </Form.Item>

        <Form.Item
          label={"Nhóm tài khoản"}
          name={"roleId"}
          rules={yupSync}
          required
        >
          {/* !!!! */}
          <Select
            placeholder="Chọn nhóm tài khoản"
            style={{ width: "100%" }}
            options={listRoles.map((item: SelectOptionType) => {
              return {
                label: _.get(item, "name", ""),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        {(roleName === "admin" || roleName === "user") && (
          <Form.Item required label={"Loại tài khoản"} name={"accountType"}>
            <Radio.Group>
              <Radio value={ACCOUNT_TYPE.ADMIN}>Quản trị</Radio>
              <Radio value={ACCOUNT_TYPE.TECH}>Kỹ thuật</Radio>
              <Radio value={ACCOUNT_TYPE.SUPPORT}>Hỗ trợ</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          label={"Trạng thái"}
          name={"status"}
          rules={yupSync}
          required
        >
          <Radio.Group>
            <Radio value={"active"}>Hoạt động</Radio>
            <Radio value={"lock"}>Khóa</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            if (!loading) form.submit();
          }}
          type="primary"
          loading={loading}
        >
          {idUpdate ? "Xác nhận" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUUser;
