import {
  Form,
  Row,
  Col,
  Button,
  Popconfirm,
  Select,
  Table,
  Layout,
  Typography,
  notification,
  Switch,
  Input,
  Space,
  Tabs,
} from "antd";
import _ from "lodash";
import moment from "moment";
const { Content } = Layout;
import { setModal } from "features/modalSlice";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { useDispatch } from "react-redux";
import { closeModal } from "utils";
function formatCurrency(price: number, locale = "vi-VN", currency = "VND") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(price);
}
function GeneralInfo(dataView: any) {
  const record = dataView?.data;

  const dispatch = useDispatch();

  function servicePackActive(record: any) {
    const now = moment();
    return (
      record.contractDetails.find(
        (contractDetail: any) =>
          contractDetail.status === "doing" &&
          now.isBefore(moment(contractDetail.endDateContract))
      ) || _.last(record.contractDetails)
    );
  }

  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 20,
        marginTop: 16,
        marginRight: 20,
        // backgroundColor: "white",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Row
          style={{
            paddingTop: 16,
            paddingLeft: 20,
            paddingRight: 20,
            background: "rgba(242, 242, 242, 0.50)",
          }}
        >
          <Col span={24}>
            <CustomLabelValue
              disableColon
              hasMarginleft={false}
              alignX={200}
              label="Mã hợp đồng"
              value={_.get(record, "code", "")}
            />
            <CustomLabelValue
              disableColon
              hasMarginleft={false}
              alignX={200}
              label="Tên chủ trại"
              value={_.get(record, "userId.name", "")}
            />

            <div
              style={{
                display: "flex",
                marginBottom: 24,
              }}
            >
              <label
                style={{
                  display: "inline-block",
                  minWidth: `${200}px`,
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                Quy mô trại
              </label>
              <span
                style={{
                  display: "inline-block",
                  marginLeft: 24,
                }}
              >
                <div>
                  <p>
                    Nái :{" "}
                    {servicePackActive(record)?.servicePackageId?.farmScaleTTNId
                      ?.name ?? 0}
                  </p>
                  <p>
                    Thịt :{" "}
                    {servicePackActive(record)?.servicePackageId?.farmScaleTTTId
                      ?.name ?? 0}
                  </p>
                </div>
              </span>
            </div>

            <CustomLabelValue
              disableColon
              hasMarginleft={false}
              alignX={200}
              label="Áp dụng triển khai "
              value={
                record?.userId?.regUserHerdBody?.typeStart === "working"
                  ? "Trại đang hoạt động"
                  : "Trại mới"
              }
            />

            <CustomLabelValue
              disableColon
              hasMarginleft={false}
              alignX={200}
              label="Thời gian ký hợp đồng"
              value={moment(_.get(record, "dateContract", "")).format(
                "DD/MM/YYYY"
              )}
            />
            <CustomLabelValue
              disableColon
              hasMarginleft={false}
              alignX={200}
              label="Hình thức theo dõi"
              value={record?.userId?.regUserHerdBody?.monitoring
                .map((item: any) => {
                  if (item === "excel") {
                    return "File excel";
                  } else if (item === "note") {
                    return "Sổ ghi chép";
                  } else {
                    return "Hình thức khác";
                  }
                })
                .join(", ")}
            />
            {!!record?.techSupporter || !!record?.dataEntryClerk ? (
              <div style={{}}>
                <p style={{ margin: 0, fontWeight: 600 }}>
                  Trạng thái:{" "}
                  <span
                    style={{ margin: 0, color: "#1890FF", fontWeight: 500 }}
                  >
                    Đã hỗ trợ
                  </span>
                </p>
                {record?.techSupporter ? (
                  <p style={{ margin: 0 }}>
                    Kĩ thuật viên:{" "}
                    <span style={{ fontWeight: 500 }}>
                      {record?.techSupporter?.name ?? ""}
                    </span>
                  </p>
                ) : (
                  <></>
                )}
                {record?.dataEntryClerk ? (
                  <p style={{ margin: 0 }}>
                    Nhân viên nhập liệu:{" "}
                    <span style={{ fontWeight: 500 }}>
                      {record?.dataEntryClerk?.name ?? ""}
                    </span>
                  </p>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div style={{ fontWeight: 500 }}>
                <p style={{ margin: 0, fontWeight: 600 }}>
                  Trạng thái:{" "}
                  <span
                    style={{ margin: 0, color: "#00B578", fontWeight: 500 }}
                  >
                    Đăng kí mới
                  </span>
                </p>
              </div>
            )}
            {/* <CustomLabelValue
              disableColon
              hasMarginleft={false}
              alignX={200}
              label="Trạng thái: "
              value={
                _.get(record, "status", "") === "doing" ? "Hoạt động" : "Đóng"
              }
            /> */}
          </Col>
        </Row>
        <div className="ant-modal-footer">
          <Button
            onClick={() => {
              closeModal(dispatch, setModal);
            }}
          >
            Quay lại
          </Button>
        </div>
      </Space>
    </Content>
  );
}
export default GeneralInfo;
